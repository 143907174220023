.offer-cardless{
	position: relative;
	padding: 110px 0 65px;
	//overflow: hidden;

	.promo-video__btn {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		height: 80px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50px;
		background: rgba(37, 50, 70, 0.3);
		box-shadow: 2px 4px 15px 3px rgba(45, 53, 71, 0.1);
		cursor: pointer;
		text-decoration: none;
		border: none;
		outline: none;
		@include transition;
		z-index: 4;

		&:hover {
			background: rgba(37, 50, 70, 0.5);
		}

		&-icon {
			margin-left: 3px;
			font-size: 24px;
			height: 24px;
			color: $white;

			path{
				fill: #fff;
			}
		}
	}

	&-header{
		margin-bottom: 22px;
		@include break-max($tab){
			text-align: center;
		}
	}
	&--row{
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 50px;
		z-index: 2;
		@include break-max($tab){
			position: relative;
			text-align: left;
			flex-direction: column;
			justify-content: center;
			gap: 20px;
			max-width: 770px;
			margin-left: auto;
			margin-right: auto;
			padding-bottom: 90px;
		}
	}
	&--col-data{
		flex: 1;
		@include break-min($tab){
			min-width: 490px;
		}

		.btn-bar{
			padding-top: 30px;
			display: flex;
			align-items: center;
			gap: 10px;
			@include break-max($tab){
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				justify-content: center;
				flex-wrap: wrap;
			}
			@include break-max($mob480){
				bottom: -30px;
			}
		}
	}
	&--col-picture{
		position: relative;
		display: block;
		@include break-min($tab){
			min-width: 680px;
		}

		img{
			width: auto;
			max-height: 556px;
		}
	}
}

#reviews{
	.shape--top{
		display: none;
	}
}