.sma{
	position: relative;
	padding: 60px 0;

	@include break-min($tab){
		padding: 100px 0;
	}

	.p{
		text-wrap: balance;
	}


	&-header{
		margin-bottom: 22px;

		@include break-max($tab){
			text-align: center;
		}

		.highlight{
			display: block;

			@include break-min($tab-xl){
				display: inline;
			}
		}
	}

	&--row{
		position: relative;
		display: flex;
		text-align: center;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
		max-width: 770px;
		margin-left: auto;
		margin-right: auto;

		@include break-min($tab){
			gap: 50px;
			flex-direction: row-reverse;
			text-align: left;
			max-width: unset;
		}
	}

	&--col-data{
		flex: 1;
		max-width: 600px;

		@include break-min($tab){
			min-width: 490px;
		}
	}

	&--col-picture{
		position: relative;
		display: block;
		text-align: center;
		max-width: 602px;


		@include break-min($tab){
			min-width: 420px;
		}
	}
}

